import React, { Component } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import $ from "jquery"
import "../../styles/about-us.scss"
import ourStory from "../../assets/images/ourStory.svg"
import brewsightLogo from "../../assets/images/brewsight.png"
import ijunctionLogo from "../../assets/images/i-junction.png"
import ohmLogo from "../../assets/images/ohm.png"
import aws from "../../assets/images/aws.png"
import gcp from "../../assets/images/gcp.png"
import mb from "../../assets/images/mb.png"
import tie from "../../assets/images/tie.png"
import si from "../../assets/images/si.png"
import so from "../../assets/images/so.png"
import sk from "../../assets/images/sk.png"
import dst from "../../assets/images/dst.png"
import alfaVentures from "../../assets/images/alfaventures.png"
import itHyderabad from "../../assets/images/it-hyderabad.png"
import derbi from "../../assets/images/derbi.png"
import avinashSosale from "../../assets/images/Avinash-Sosale.jpg"
import biswajitDas from "../../assets/images/Biswajit-Das.jpg"
import pruthviRaj from "../../assets/images/Pruthviraj.jpg"
import sameer from "../../assets/images/Sameer.jpg"
import srikant from "../../assets/images/Srikant.jpg"
import linkedInLogo from "../../assets/images/linkedin-logo.png"
import hand from "../../assets/images/purple-hand.jpg"


const teamMembers = [
  {
    name: 'SAMEER PATNAIK',
    designation: 'Co-Founder & Chief Executive Officer',
    desc: `Sameer Patnaik dons multiple hats for Machstatz, including business developement and 
    product developement. He holds a B.Tech degree in Electronics & Instrumentation from National 
    Institute of Science & Technology and an MBA from Amity Business School,Noida. He has in-depth 
    expertise in Industrial IoT,M2M,PLC,DCS,SCADA,Smart Sensors & Gateways. Before Machstatz he 
    worked as an Industrial Automation Engineer.`,
    profilePic: sameer,
    linkedInLink: 'https://www.linkedin.com/in/sameer-patnaik-31b4b019/'
  },
  {
    name: 'PRUTHVIRAJ SUBUDHI',
    designation: 'Co-Founder & Chief Technology Officer',
    desc: `Every successful technology company needs a geek.With his inquisitive and curious 
    mindset, Pruthviraj Subudhi is the perfect fit for that role. He holds a B.Tech degree 
    Electronics & Telecommunications from National Institute of Science & Technology. Before 
    Machstatz, he worked in L&T, HCL and GE.Having built various technology products, he has a 
    unique blend of embedded devices and software technology expertise.`,
    profilePic: pruthviRaj,
    linkedInLink: 'https://www.linkedin.com/in/pruthviraj-subudhi/'
  },
  {
    name: 'SRIKANTH GUPTA',
    designation: 'Co-Founder & Chief Analytics Officer',
    desc: `Srikant Gupta is Machstatz's storyteller. What else would you call a person who can 
    effortlessly decode intricate data patterns into engineering stories? He holds a B.Tech degree 
    in Computer Science Engineering from National Institute of Science & Technology. Before 
    Machstatz, he worked in a leading analytics firm, GroupM. At GroupM, he used his skill as 
    mentioned earlier to influence its strategic decisions and growth.`,
    profilePic: srikant,
    linkedInLink: 'https://www.linkedin.com/in/a-srikant-gupta-aa203985/'
  },
  {
    name: 'AVINASH SOSALE',
    designation: 'Mentor & Partner',
    desc: `Everyone needs a mentor who has already eaten dirt and come out victorious. We are 
    fortunate to have Avinash Sosale as our mentor.He is an avid IoT and Lean manufacturing 
    enthusiast, investing and heading companies in Manufacturing.IoT, Software Automation, 
    Early Education &N E-Commerce. Additionally, he has served on the board of Major Automative 
    Suppliers and handled Lean Manufacturing,Marketing, Purchase & HR.`,
    profilePic:avinashSosale,
    linkedInLink: 'https://www.linkedin.com/in/avinashsosale/'
  },
  {
    name: 'BISWAJIT DAS',
    designation: 'Technical Advisor & Consultant',
    desc: `Having a seasoned industrial expert as an advisor and consultant is a boon for any 
    startup. Biswajith Das has a vast experience of 34 years in setting up the modern 
    manufacturing and fabrication plants, latest 3D based software and CNC machines, and 
    Automation. His current expertise on Industry 4.0 knowledge has helped us shorten our 
    learning curve drastically.`,
    profilePic: biswajitDas,
    linkedInLink: 'https://www.linkedin.com/in/biswajit-das-4b9346139/'
  },

]

const TeamMember = ({ data }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-5 col-md-5 text-center order-lg-2 order-md-2 p-top-40">
          <div className="team-img-wrapper float-lg-right">
            <img
              src={data.profilePic}
              className="responsive-img-about-us"
            />
           
          </div>
        </div>
        <div className="col-12 col-lg-7 col-md-7 team-member-desc-pt order-lg-1 order-md-1">
          <h2 className="team-mem-name">{data.name}</h2>
          <div className="team-mem-desc-wrapper">
          <h4 className="team-mem-des">
            {data.designation}
          </h4>
          <div className="d-none d-md-block d-lg-block team-link-btn">
              <a
                target="_blank"
                href={data.linkedInLink}
              >
                <img
                  src={linkedInLogo}
                  className="linkedin-icon"
                />
              </a>
            </div>
          </div>
          <br />
          <p className="team-read-more member-1 text-justify">
            {data.desc}
          </p>
        </div>
      </div>
    </div>
  )
}

const techPartner = [
  {
    image: aws
  },
  {
    image: gcp
  },
  {
    image: mb
  },
  // {
  //   image: tie
  // }
]

const channelPartner = [
  {
    image: ijunctionLogo,
    address: 'Raipur, Chhattisgarh'
  },
  {
    image: brewsightLogo,
    address: 'Ahmedabad Gujrat'
  },
  {
    image: ohmLogo ,
    address: 'Bangalore, Karnataka'
  }
]

const supportedByData = [
  {
    image: si,
    imageClassName: 'responsive-img-partners',
    address: 'INVEST INDIA 110, Vigyan Bhavan Annexe, 001, Maulana Azad Road, New Delhi 110 001'
  },
  {
    image: so,
    imageClassName: 'responsive-img-partners',
    address: 'IED Odisha, Sector-A,zone-A , Plot no-123, Mancheswar Industrial Estate, Bhubaneswar, 751010'
  },
  {
    image: sk,
    imageClassName: 'responsive-img-investors',
    address: 'Karnataka Startup Cell, BMTC - Central Offices, TTMC "B" Block, Shanthinagar, 560027'
  },
  {
    image: dst,
    imageClassName: 'responsive-img-investors',
    address: 'Technology Bhavan, New Mehrauli Road, Block C, Qutab Institutional Area, Delhi 110016'
  },
  {
    image: tie,
    imageClassName: 'responsive-img-investors',
    address: 'Technology Bhavan, New Mehrauli Road, Block C, Qutab Institutional Area, Delhi 110016'
  },
  
]

const investorsData = [
  {
    image: alfaVentures,
    address: 'New Delhi'
  },
  {
    image: itHyderabad,
    address: 'Professor CR Rao Rd, Gachibowli, Hyderabad, Telangana 500032'
  },
  {
    image: derbi,
    address: 'Dayananda Sagar university, 2nd Floor, Block 1, Hongasandra Village, Bengaluru,560068'
  },
]

class About extends Component {
  componentDidMount() {
    $(document).ready(function () {
      $(window).scroll(function () {
        var positiontop = $(document).scrollTop()
        console.log(positiontop)
        //viewport
        if (positiontop > 100 && positiontop < 120) {
          $("box1").addClass("box1")
          $("box2").addClass("box2")
          $("box3").addClass("box3")
          $("box4").addClass("box4")
          $("box5").addClass("box5")
          $("box6").addClass("box6")
          $("box7").addClass("box7")
          $("box8").addClass("box8")
        }
        if (positiontop > 1000 && positiontop < 1100) {
          $(".member-1").addClass("team-anim-1")
        }
        if (positiontop > 1750 && positiontop < 1800) {
          $(".member-2").addClass("team-anim-1")
        }
        if (positiontop > 2600 && positiontop < 2650) {
          $(".member-3").addClass("team-anim-1")
        }
        if (positiontop > 3400 && positiontop < 3450) {
          $(".member-4").addClass("team-anim-1")
        }
        if (positiontop > 4100 && positiontop < 4200) {
          $(".member-5").addClass("team-anim-1")
        }
      })
    })
  }

  componentWillUnmount() { }

  render() {
    return (
      <Layout>
        <SEO title="About" />
        <div className="container text-center py-4">
          <div className="row">
            <h5 className="sub-title-about-us mt-4 col-12">Our Vision</h5>
            <h1 className="vision-about-us col-12 col-lg-10 offset-lg-1 text-justify">
              We help you make better operational strategies using AI and
              predictive algorithm. Our primary goal is to improve your plant's
              operational efficiency.
            </h1>
          </div>
        </div>
        <div>
          <div className="container pt-3 pt-lg-5 mt-3 pt-lg-5">
            <h4 className="sub-title-about-us">Our Story</h4>
            <div className="row about">
              <div className="col-12 col-lg-7 col-md-6 p-0">
                <p className="team-read-more text-justify">
                  Machstatz was conceptualized with one and one aim alone - take
                  organizations engaged in the machine intensive industries next
                  level.
                </p>
                <p className="team-read-more text-justify">
                  So far, AI and IoT technologies have led various breakthroughs
                  in smart technology and robotics. It's about time that
                  traditional industries of manufacturing, assembly line,
                  extraction etc, also benefit from AI and IoT.
                </p>
                <p className="team-read-more text-justify">
                  With that in mind, Machstatz began in 2017 with a small team
                  of highly experienced techies. Our core team has extensive
                  experience and expertise in diverse fields of advanced tech
                  such as Industrial Instrumentation & Automation, Big Data,
                  Database Management, Data Security, Data Analytics, Artificial
                  Intelligence and Machine Learning.
                </p>
                <p className="team-read-more text-justify">
                  In short, our team is well equipped with the expertise needed
                  to upgrade your organization with Industrial IoT.
                </p>
              </div>
              <div className="col-12 col-lg-5 col-md-6 p-0">
                <div className="our-story-img">
                  <img
                    src={ourStory}
                    alt="Our story"
                    className="responsive-img"
                  />
                </div>
              </div>
            </div>
            {/* <div className="main-box-div">
              <div className="about-vr-main">
                <div className="about-vr">
                  <span></span>
                </div>
              </div>
              <div className="box box1 m-0" id="box1">2017 - The Beginning</div>
              <div className="box box2 m-0" id="box2">Sept 2017 - Received Angel Investment</div>
              <div className="box box3 m-0" id="box3">Oct 2017 - Acquired Stanzen Engineering as our first client
              </div>
              <div className="box box4 m-0" id="box4">April 2018 - Received grant from IIMA,
          at Econimic Times Power of Ideas </div>
              <div className="box box5 m-0" id="box5">Oct 2018 - Incubated by DERBI Foundation</div>
              <div className="box box6 m-0" id="box6">May 2019 - Received Seed Round Funds from DERBI Foundation,
           A Technology Business Incubator supported by Department of Science & Technology, Govt. of India</div>
              <div className="box box7 m-0" id="box7">July 2019 - Acquired Borosil as our client</div>
              <div className="box box8 m-0" id="box8">Sept 2019 - Acquired Arvind Textiles as our client</div>
            </div> */}
            <br/>
            <br/>
            <br/>
            <h4 className="sub-title-about-us d-none d-lg-block">Team</h4>
          </div>
          {teamMembers.map((member, index) => {
            return (
              <div className={`team-${index % 2 ? 1 : 2} full-height`}>
                <TeamMember data={member} />
              </div>
            )
          })}
        </div>
        <br />
        <br />
        <div className="container" style={{marginBottom:'65px'}}>
          <div className="row m-0">
            <div className="col-12" id="tech">
              <h4 className="sub-title-about-us">Technical Partners</h4>
            </div>
          </div>
          <div className="d-flex justify-content-between flex-wrap align-items-center mt-5">
            {techPartner.map(item => {
              return (
                <div className="partners-img">
                  <img
                    src={item.image}
                    className="responsive-img-partners"
                  />
                </div>
              )
            })}
          </div>
        </div>
        <br />
        <div className="container">
          <div className="row m-0">
            <div className="col-12 d-flex align-items-center">
              <h4 className="sub-title-about-us">Channel Partners</h4>
              <div>
              <button type="button" class="btn btn-primary mx-5" data-toggle="modal" data-target="#exampleModalCenter">
                Join Us
              </button>

              {/* <!--Modal--> */}
              <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                  <div class="modal-content flex-row">
                    {/* <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div> */}
                    {/* <div class="modal-body d-flex"> */}
                      <div className="col-6 p-0">
                          <img style={{width:'100%', margin:'0px', height:'100%'}} src={hand} alt="hands"/>
                      </div>
                      <div className="col-6 p-0">
                          <div>
                            <form className="text-center" style={{padding:'60px 15px'}}>
                            <h3 style={{marginBottom:'30px'}}>To Become Our Channel Partner</h3>
                            <div style={{width:'80%', margin:'0 auto'}}>
                            <div className="d-flex align-items-baseline" style={{justifyContent:'space-between'}}>
                            <p>Name : </p>
                            <input type="text" style={{borderTop:'0',borderRight:'0',borderLeft:'0'}} required/>
                            </div>
                            <div className="d-flex align-items-baseline" style={{justifyContent:'space-between'}}>
                            <p>Mobile no. : </p>
                            <input type="tel" pattern="[0-9]{10}" style={{borderTop:'0',borderRight:'0',borderLeft:'0'}} required/>
                            </div>
                            <div className="d-flex align-items-baseline" style={{justifyContent:'space-between'}}>
                            <p>E-mail : </p>
                            <input type="email" style={{borderTop:'0',borderRight:'0',borderLeft:'0'}} required/>
                            </div>
                            </div>
                            <div>
                            <button type="button" class="btn btn-primary" style={{margin:'20px'}}>Submit</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal" style={{margin:'20px'}}>Close</button>
                            </div>
                            </form>
                            
                          </div>
                      </div>
                    {/* </div> */}
                    {/* <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                      <button type="button" class="btn btn-primary">Save changes</button>
                    </div> */}
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center mt-5">
            {channelPartner.map(item => {
              return (
                <div className="col-12 col-sm-6 col-md-4  partners-img" >
                  <div style={{ marginBottom: '10px' }}>
                    <img
                      src={item.image}
                      className="responsive-img-investors"
                    />
                  </div>
                  <p style={{ fontSize: '15px', width: '50%', margin: '0 auto' }}>{item.address}</p>
                </div>
              )
            })}
          </div>
          <br />
          <div className="container" id='supportedBy'  style={{paddingTop:'65px'}}>
            <h4 className="sub-title-about-us">Supported By</h4>
            <div className="row mt-5 align-items-center" >
              {supportedByData.map(item => {
                return (
                  <div className="col-sm-6 col-md-4 col-lg-4 partners-img py-2">
                    <div style={{ marginBottom: '10px' }}>
                      <img
                        src={item.image}
                        className={item.imageClassName}
                      />
                    </div>
                    {/* <p style={{ fontSize: '15px', width: '50%', margin: '0 auto' }}>{item.address}</p> */}
                  </div>
                )
              })}
            </div>
          </div>
          {/* <br />
          <div className="container partners-full-height" id="our-investors">

            <h4 className="sub-title-about-us">Our Investors</h4>
            <div className="row mt-5" >
              {investorsData.map(item => {
                return (
                  <div className="col-sm-6 col-md-4 col-lg-4 partners-img">
                    <div style={{ marginBottom: '10px' }}>
                      <img
                        src={item.image}
                        className="responsive-img-investors"
                      />
                    </div>
                    <p style={{ fontSize: '15px', width: '50%', margin: '0 auto' }}>{item.address}</p>
                  </div>
                )
              })}
            </div>
          </div> */}
        </div>
        <br />
      </Layout>
    )
  }
}

export default About
